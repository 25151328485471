// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EyXAk{height:calc(100% - var(--header-height));margin-right:4px;padding:8px 20px 24px 24px;word-break:normal}.ckd0H{height:calc(100% - var(--header-height))}", "",{"version":3,"sources":["webpack://./src/components/assistant/assistantPopover/assistantContent/AssistantContent.module.scss"],"names":[],"mappings":"AAAA,OACE,wCAAA,CACA,gBAAA,CACA,0BAAA,CACA,iBAAA,CAGF,OACE,wCAAA","sourcesContent":[".container {\n  height: calc(100% - var(--header-height));\n  margin-right: 4px;\n  padding: 8px 20px 24px 24px;\n  word-break: normal;\n}\n\n.spinnerContainer {\n  height: calc(100% - var(--header-height));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "EyXAk",
	"spinnerContainer": "ckd0H"
};
export default ___CSS_LOADER_EXPORT___;
