import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import styles from 'components/assistant/chat/chatBottomText/ChatBottomText.module.scss'
import { ConversationDto } from 'types/dto/ConversationDto'

interface Props {
  conversation: ConversationDto | undefined
  isConversationSaved: boolean
  isConversationFromHistory: boolean
  onClearConversation: ({
    conversationId,
    startNewConversation,
  }: {
    conversationId: string
    startNewConversation?: boolean
  }) => void
  tokenLimitReached: boolean
}

export const ChatBottomText = ({
  conversation,
  isConversationSaved,
  isConversationFromHistory,
  onClearConversation,
  tokenLimitReached,
}: Props) => {
  const { t } = useTranslation()

  if (conversation && tokenLimitReached) {
    return (
      <>
        {isConversationSaved ? (
          <div>
            <WppTypography tag="span" type="s-body" className={styles.tokenLimitReachedText}>
              {t('assisstant_errors.char_limit_reached.saved_conversation')}{' '}
            </WppTypography>
            <button
              className={styles.clearConversationButton}
              onClick={() => onClearConversation({ conversationId: conversation.id, startNewConversation: true })}
            >
              <WppTypography tag="span" type="s-body" className={styles.clearConversationButtonText}>
                {t('start_new_conversation')}
              </WppTypography>
            </button>
          </div>
        ) : (
          <div>
            <WppTypography tag="span" type="s-body" className={styles.tokenLimitReachedText}>
              {t('assisstant_errors.char_limit_reached.not_saved_conversation.0')}{' '}
            </WppTypography>
            <button
              className={styles.clearConversationButton}
              onClick={() => onClearConversation({ conversationId: conversation.id, startNewConversation: false })}
            >
              <WppTypography tag="span" type="s-body" className={styles.clearConversationButtonText}>
                {t('clear_conversation')}
              </WppTypography>
            </button>
            <WppTypography tag="span" type="s-body" className={styles.tokenLimitReachedText}>
              {' '}
              {t('assisstant_errors.char_limit_reached.not_saved_conversation.1')}
            </WppTypography>
          </div>
        )}
      </>
    )
  }

  return (
    <>
      {!isConversationFromHistory && conversation?.messages && conversation.messages.length > 0 && (
        <button onClick={() => onClearConversation({ conversationId: conversation.id })}>
          <WppTypography tag="span" type="s-body" className={styles.clearConversationButtonText}>
            {t('clear_conversation')}
          </WppTypography>
        </button>
      )}
    </>
  )
}
