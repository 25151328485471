import { useQueryClient } from '@tanstack/react-query'
import { useOs } from '@wpp-open/react'
import { isAxiosError } from 'axios'

import { useCreateConversation } from 'api/assistant/mutations/useCreateConversation'
import { useCreateQuestion } from 'api/assistant/mutations/useCreateQuestion'
import { useDeleteConversation } from 'api/assistant/mutations/useDeleteConversation'
import { usePatchConversation } from 'api/assistant/mutations/usePatchConversation'
import { useFetchSavedConversations } from 'api/assistant/queries/useFetchSavedConversations'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { ConversationDto, ConversationMessageDto } from 'types/dto/ConversationDto'

interface CreateEmptyConversationArgs {
  saved: boolean
  question: string
}

interface AskQuestionArgs {
  conversationId: string
  question: string
}

interface PatchConversationArgs {
  conversationId: string
  name?: string
  saved: boolean
}

interface DeleteConversationArgs {
  conversationId: string
}

export const useAssistant = () => {
  const { osContext } = useOs()
  const tenantId = osContext.tenant.id
  const { id: userId } = osContext.userDetails
  const queryClient = useQueryClient()

  const { mutateAsync: createConversation } = useCreateConversation()
  const { mutateAsync: createQuestion } = useCreateQuestion()
  const { mutateAsync: patchConversationMutation } = usePatchConversation()
  const { mutateAsync: deleteConversationMutation } = useDeleteConversation()

  const startConversation = async ({
    saved,
    question,
  }: CreateEmptyConversationArgs): Promise<ConversationDto | number | undefined> => {
    try {
      const response = await createConversation({
        tenantId,
        userId,
        saved,
        messages: ['', question],
      })
      return response.data
    } catch (err) {
      if (isAxiosError(err)) {
        return err.response?.status
      }
      console.error(err)
    }
  }

  const askQuestion = async ({
    conversationId,
    question,
  }: AskQuestionArgs): Promise<ConversationMessageDto | ConversationMessageDto[] | number | undefined> => {
    try {
      const response = await createQuestion({ conversationId, question })
      return response.data
    } catch (err) {
      if (isAxiosError(err)) {
        return err.response?.status
      }
      console.error(err)
    }
  }

  const patchConversation = async ({ conversationId, name, saved }: PatchConversationArgs) => {
    try {
      await patchConversationMutation({ conversationId, name, saved })
      queryClient.invalidateQueries([ApiQueryKeys.SAVED_CONVERSATIONS])
    } catch (err) {
      console.error(err)
    }
  }

  const deleteConversation = async ({ conversationId }: DeleteConversationArgs) => {
    try {
      await deleteConversationMutation({ chatId: conversationId })
      queryClient.invalidateQueries([ApiQueryKeys.SAVED_CONVERSATIONS])
    } catch (err) {
      console.error(err)
    }
  }

  const { data: savedConversations, isLoading: isLoadingSavedConversations } = useFetchSavedConversations({
    params: { itemsPerPage: 1000, userId: userId },
  })

  return {
    startConversation,
    askQuestion,
    patchConversation,
    deleteConversation,
    isLoadingSavedConversations,
    savedConversations,
  }
}
