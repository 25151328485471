// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LiojR{margin-bottom:12px;padding:12px;background-color:var(--wpp-grey-color-000);border-radius:var(--wpp-border-radius-m);box-shadow:var(--wpp-box-shadow-xs)}.BXw2S{width:100%}.RkTfO{text-transform:uppercase}.TPgdf{margin-bottom:10px}.Z5qPM{color:var(--wpp-grey-color-800)}.SZF7s{padding:6px}.SZF7s:hover .Z5qPM{color:var(--wpp-primary-color-500)}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/chatBubble/dataAnswer/dataItem/DataItem.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,YAAA,CACA,0CAAA,CACA,wCAAA,CACA,mCAAA,CAGF,OACE,UAAA,CAGF,OACE,wBAAA,CAGF,OACE,kBAAA,CAGF,OACE,+BAAA,CAGF,OACE,WAAA,CAGE,oBACE,kCAAA","sourcesContent":[".card {\n  margin-bottom: 12px;\n  padding: 12px;\n  background-color: var(--wpp-grey-color-000);\n  border-radius: var(--wpp-border-radius-m);\n  box-shadow: var(--wpp-box-shadow-xs);\n}\n\n.mainContent {\n  width: 100%;\n}\n\n.name {\n  text-transform: uppercase;\n}\n\n.description {\n  margin-bottom: 10px;\n}\n\n.downloadIcon {\n  color: var(--wpp-grey-color-800);\n}\n\n.downloadLink {\n  padding: 6px;\n\n  &:hover {\n    .downloadIcon {\n      color: var(--wpp-primary-color-500);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "LiojR",
	"mainContent": "BXw2S",
	"name": "RkTfO",
	"description": "TPgdf",
	"downloadIcon": "Z5qPM",
	"downloadLink": "SZF7s"
};
export default ___CSS_LOADER_EXPORT___;
