import { WppActionButton, WppIconSend, WppTypography } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { useState, useMemo, FormEvent, KeyboardEvent, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { MentionsInput, Mention } from 'react-mentions'
import { Updater as ImmerUpdater } from 'use-immer'

import { AnswerLoadingPlaceholder } from 'components/assistant/chat/answerLoadingPlaceholder/AnswerLoadingPlaceholder'
import styles from 'components/assistant/chat/Chat.module.scss'
import { ChatBottomText } from 'components/assistant/chat/chatBottomText/ChatBottomText'
import { ChatBubble } from 'components/assistant/chat/chatBubble/ChatBubble'
import { OpenAIAnswer } from 'components/assistant/chat/chatBubble/openAIAnswer/OpenAIAnswer'
import { Question } from 'components/assistant/chat/chatBubble/question/Question'
import { ChatInstructions } from 'components/assistant/chat/chatInstructions/ChatInstructions'
import { audiencePrompAnswer, isAudiencePromp } from 'components/assistant/chat/utils/utils'
import { Flex } from 'components/common/flex/Flex'
import { useAssistant } from 'hooks/useAssistant'
import { useHasPermissions } from 'hooks/useHasPermissions'
import { ConversationDto, QuestionDto, ConversationMessageDto } from 'types/dto/ConversationDto'

const NEW_CONVERSATION_FLAG = 'newConversationFlag'
const QUESTION_MAX_LENGTH = 2000

const mentionOptions = [
  { id: 'appsuggestion', display: 'appsuggestion' },
  { id: 'audience', display: 'audience' },
  { id: 'campaign', display: 'campaign' },
  { id: 'brand', display: 'brand' },
  { id: 'data', display: 'data' },
  { id: 'BAV', display: 'BAV' },
  { id: 'inspiration', display: 'inspiration' },
  { id: 'recommendation', display: 'recommendation' },
]

interface Props {
  conversation: ConversationDto | undefined
  isConversationSaved: boolean
  isConversationFromHistory: boolean
  updateConversation: ImmerUpdater<ConversationDto | undefined>
  onClearConversation: ({
    conversationId,
    startNewConversation,
  }: {
    conversationId: string
    startNewConversation?: boolean
  }) => void
  questionInputVisible: boolean
}

export const Chat = ({
  conversation,
  isConversationSaved,
  isConversationFromHistory,
  updateConversation,
  onClearConversation,
  questionInputVisible,
}: Props) => {
  const { t } = useTranslation()
  const { hasAccessToChatAssistantConfig } = useHasPermissions()
  const { startConversation, askQuestion } = useAssistant()
  const { osContext } = useOs()

  const refScrollBottomDiv = useRef<HTMLDivElement>(null)

  const [question, setQuestion] = useState('')
  const [questionCharCount, setQuestionCharCount] = useState(0)
  const [firstQuestion, setFirstQuestion] = useState('')
  const [conversationIdOfLoadingAnswer, setConversationIdOfLoadingAnswer] = useState<string | null>(null)
  const [showErrorChatBubble, setShowErrorChatBubble] = useState(false)
  const [showTokenLimitReached, setShowTokenLimitReached] = useState(false)

  const tenantUrl = osContext.tenant.homeUrl.slice(0, -1)

  const answerIsLoading = useMemo(
    () => conversationIdOfLoadingAnswer === conversation?.id || conversationIdOfLoadingAnswer === NEW_CONVERSATION_FLAG,
    [conversationIdOfLoadingAnswer, conversation],
  )

  useEffect(() => {
    setShowErrorChatBubble(false)
  }, [conversation?.id])

  useEffect(() => {
    const scrollImmediateTimeout = setTimeout(() => {
      if (conversation) {
        refScrollBottomDiv.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        })
      }
    }, 0)

    return () => {
      clearTimeout(scrollImmediateTimeout)
    }
  }, [conversation])

  const removeQuestionsFromMessages = (messages: ConversationMessageDto[]) => {
    return messages.filter((message: any) => message.type !== 'PROMPT')
  }

  const onQuestionKeyDown = (event: KeyboardEvent<HTMLTextAreaElement> | KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSubmitQuestion()
    }
  }

  const onSubmitQuestion = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    handleSubmitQuestion()
  }

  const handleSubmitQuestion = async () => {
    const formattedQuestion = question.replace(/\*/g, '')
    if (!formattedQuestion.trim() || formattedQuestion.length > QUESTION_MAX_LENGTH) return

    setConversationIdOfLoadingAnswer(conversation?.id ? conversation.id : NEW_CONVERSATION_FLAG)
    const questionCopy = formattedQuestion
    setQuestion('')
    setQuestionCharCount(0)
    try {
      if (!conversation) {
        setFirstQuestion(questionCopy)
        const newConversation = await startConversation({ saved: isConversationSaved, question: questionCopy })
        if (typeof newConversation === 'number') {
          if (newConversation === 429) {
            setShowTokenLimitReached(true)
          } else {
            setShowErrorChatBubble(true)
          }
          return
        }
        if (newConversation) {
          setFirstQuestion('')
          if (isAudiencePromp(questionCopy)) {
            newConversation.messages[newConversation.messages.length - 1] = audiencePrompAnswer(
              questionCopy,
              newConversation.id,
              tenantUrl,
            )
          }
          updateConversation(newConversation)
        } else {
          setShowErrorChatBubble(true)
        }
        return
      }
      const questionMessage = {
        content: questionCopy,
        role: 'user',
        type: 'PROMPT',
        chatId: conversation.id,
      } as QuestionDto

      updateConversation(conversationDraft => {
        if (!conversationDraft) return
        conversationDraft.messages = [...conversation.messages, questionMessage]
      })

      const answerResponse = await askQuestion({ conversationId: conversation.id, question: questionCopy })
      if (typeof answerResponse === 'number') {
        if (answerResponse === 429) {
          setShowTokenLimitReached(true)
        } else {
          setShowErrorChatBubble(true)
        }
        return
      }
      if (answerResponse) {
        updateConversation(conversationDraft => {
          if (!conversationDraft) return

          const answers = isAudiencePromp(questionCopy)
            ? [audiencePrompAnswer(questionCopy, conversation.id, tenantUrl)]
            : Array.isArray(answerResponse)
            ? removeQuestionsFromMessages(answerResponse)
            : [answerResponse]
          conversationDraft.messages = [...conversation.messages, questionMessage, ...answers]
        })
      } else {
        setShowErrorChatBubble(true)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setConversationIdOfLoadingAnswer(null)
    }
  }

  let suggestionsStyle = {
    input: {
      height: 136,
      overflow: 'auto',
    },
    highlighter: {
      height: 136,
      padding: '9px 48px 9px 12px',
      lineHeight: '22px',
      overflow: 'auto',
    },
    suggestions: {
      item: {
        minWidth: '180px',
        marginBottom: '4px',
        padding: '5px 8px',
        borderRadius: 'var(--wpp-border-radius-s)',
        '&focused': {
          backgroundColor: 'var(--wpp-grey-color-200)',
        },
      },
    },
  }

  return (
    <div>
      <div className={styles.chatWrapper}>
        <div className={styles.chat}>
          {!firstQuestion && !conversation && <ChatInstructions />}
          {firstQuestion && (
            <div className="cancel-drag">
              <Question text={firstQuestion} />
            </div>
          )}
          {conversation?.messages.slice(1).map((message, index) => (
            <ChatBubble message={message} key={index} />
          ))}
          {answerIsLoading && <AnswerLoadingPlaceholder />}
          {showErrorChatBubble && <OpenAIAnswer text={t('assisstant_errors.no_answer')} isError />}
          <ChatBottomText
            conversation={conversation}
            isConversationSaved={isConversationSaved}
            isConversationFromHistory={isConversationFromHistory}
            onClearConversation={onClearConversation}
            tokenLimitReached={showTokenLimitReached}
          />
          <div ref={refScrollBottomDiv} />
        </div>
      </div>

      {questionInputVisible && (
        <form
          className={clsx(
            styles.questionInputWrapper,
            hasAccessToChatAssistantConfig && styles.questionInputWrapperFooterOffset,
          )}
          onSubmit={onSubmitQuestion}
        >
          <MentionsInput
            placeholder={t('question_input.placeholder')}
            value={question}
            onChange={e => {
              setQuestion(e.target.value)
              setQuestionCharCount(e.target.value.length)
            }}
            maxLength={QUESTION_MAX_LENGTH}
            disabled={answerIsLoading || showTokenLimitReached}
            onKeyDown={onQuestionKeyDown}
            customSuggestionsContainer={children => (
              <div className={styles.mentionSuggestionsContainer}>{children}</div>
            )}
            className={clsx(
              styles.mentionsInput,
              question.length > QUESTION_MAX_LENGTH && styles.mentionsInputError,
              'cancel-drag',
            )}
            style={suggestionsStyle}
          >
            <Mention
              trigger="@"
              markup="@*__display__*"
              displayTransform={mention => `@${mention}`}
              data={mentionOptions}
              appendSpaceOnAdd
              className={styles.mention}
              renderSuggestion={suggestion => (
                <div className={styles.mentionSuggestion}>
                  <WppTypography type="s-body">{suggestion.display}</WppTypography>
                </div>
              )}
            />
          </MentionsInput>

          <Flex justify="end" className={styles.questionCharacterLimit}>
            <WppTypography
              type="xs-body"
              className={clsx(
                question.length > QUESTION_MAX_LENGTH
                  ? styles.questionCharacterLimitLabelError
                  : styles.questionCharacterLimitLabel,
              )}
            >
              {t('question_input.characters_label')}:
            </WppTypography>
            <WppTypography
              type="xs-strong"
              className={clsx(question.length > QUESTION_MAX_LENGTH && styles.questionCharacterLimitLabelError)}
            >
              &nbsp;{questionCharCount}/{QUESTION_MAX_LENGTH}
            </WppTypography>
          </Flex>
          <WppActionButton
            type="submit"
            className={clsx(styles.submitQuestionButton, 'cancel-drag')}
            disabled={answerIsLoading}
          >
            <WppIconSend size="m" color="var(--wpp-grey-color-600)" />
          </WppActionButton>
        </form>
      )}
    </div>
  )
}
