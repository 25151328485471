import ReactMarkdown from 'react-markdown'

import styles from 'components/assistant/chat/chatBubble/markdownText/MarkdownText.module.scss'

interface Props {
  text: string
}

export const MarkdownText = ({ text }: Props) => {
  return (
    <ReactMarkdown linkTarget="_blank" className={styles.markdownText}>
      {text}
    </ReactMarkdown>
  )
}
