import { SegmentedControlChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppSegmentedControlCustomEvent } from '@platform-ui-kit/components-library/loader'
import {
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppToggle,
  WppTypography,
  WppIconChevron,
} from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import styles from 'components/assistant/assistantPopover/assistantContent/headerControls/HeaderControls.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { AssistantTabs } from 'constants/ui'

interface Props {
  selectedTab: AssistantTabs
  onTabChange: (tab: AssistantTabs) => void
  showSaveConversationToggle: boolean
  isSaveConversationChecked: boolean
  onSaveConversationCheckChange: (checked: boolean) => void
  showBackButton: boolean
  onBackButtonClick: () => void
}

export const HeaderControls = ({
  selectedTab,
  onTabChange,
  showSaveConversationToggle,
  isSaveConversationChecked,
  onSaveConversationCheckChange,
  showBackButton,
  onBackButtonClick,
}: Props) => {
  const { t } = useTranslation()

  const handleTabChange = (event: WppSegmentedControlCustomEvent<SegmentedControlChangeEventDetail>) => {
    onTabChange(event.detail.value as AssistantTabs)
  }

  return (
    <div className={styles.headerControlsWrapper}>
      <Flex direction="row" justify="between" align="center" gap={8}>
        {showBackButton ? (
          <button className={styles.backButton} onClick={onBackButtonClick}>
            <Flex align="center">
              <WppIconChevron size="m" direction="left" className={styles.backButtonIcon} />
              <WppTypography tag="span" type="s-body" className={styles.backButtonText}>
                {t('back_to_history')}
              </WppTypography>
            </Flex>
          </button>
        ) : (
          <WppSegmentedControl size="s" value={selectedTab} onWppChange={handleTabChange}>
            <WppSegmentedControlItem value={AssistantTabs.CHAT}>{t('chat')}</WppSegmentedControlItem>
            <WppSegmentedControlItem value={AssistantTabs.SAVED_CONVERSATIONS}>
              {t('saved_conversations')}
            </WppSegmentedControlItem>
          </WppSegmentedControl>
        )}
        {showSaveConversationToggle && (
          <WppToggle
            labelConfig={{
              text: t('save_conversation'),
              icon: undefined,
            }}
            required
            checked={isSaveConversationChecked}
            onWppChange={({ detail: { checked } }) => onSaveConversationCheckChange(checked)}
            className="cancel-drag"
          />
        )}
      </Flex>
    </div>
  )
}
