import { WppIconFile, WppTypography, WppTag, WppIconDownload } from '@platform-ui-kit/components-library-react'

import styles from 'components/assistant/chat/chatBubble/dataAnswer/dataItem/DataItem.module.scss'
import { Flex } from 'components/common/flex/Flex'

interface Props {
  name: string
  description: string
  tags: string[]
  url: string
}

export const DataItem = ({ name, description, tags, url }: Props) => {
  return (
    <Flex gap={12} className={styles.card}>
      <WppIconFile height={98} width={98} />
      <div className={styles.mainContent}>
        <WppTypography className={styles.name} type="2xs-strong">
          {name}
        </WppTypography>
        <WppTypography className={styles.description} type="s-body">
          {description}
        </WppTypography>
        <Flex justify="between" align="center">
          <Flex gap={8} wrap="wrap">
            {tags.slice(0, 2).map((tag, index) => (
              <WppTag variant="neutral" label={tag} key={index} />
            ))}
          </Flex>
          <a key={name || 'Document'} href={url} target="_blank" rel="noreferrer" className={styles.downloadLink}>
            <WppIconDownload height={20} width={20} className={styles.downloadIcon} />
          </a>
        </Flex>
      </div>
    </Flex>
  )
}
