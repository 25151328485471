import clsx from 'clsx'

import { AudienceAnswer } from 'components/assistant/chat/chatBubble/audienceAnswer/AudienceAnswer'
import { BrandAnswer } from 'components/assistant/chat/chatBubble/brandAnswer/BrandAnswer'
import { CampaignAnswer } from 'components/assistant/chat/chatBubble/campaignAnswer/CampaignAnswer'
import styles from 'components/assistant/chat/chatBubble/ChatBubble.module.scss'
import { DataAnswer } from 'components/assistant/chat/chatBubble/dataAnswer/DataAnswer'
import { OpenAIAnswer } from 'components/assistant/chat/chatBubble/openAIAnswer/OpenAIAnswer'
import { Question } from 'components/assistant/chat/chatBubble/question/Question'
import { StarfishAnswer } from 'components/assistant/chat/chatBubble/starfishAnswer/StarfishAnswer'
import { ConversationMessageDto } from 'types/dto/ConversationDto'

interface Props {
  message: ConversationMessageDto
}

export const ChatBubble = ({ message }: Props) => {
  const renderMessage = () => {
    switch (message.type) {
      case 'PROMPT':
        return <Question text={message.content} />
      case 'OPEN_API':
        return <OpenAIAnswer text={message.content} />
      case 'STARFISH':
        return <StarfishAnswer answer={message} />
      case 'GENIES_CAMPAIGN':
        return (
          <CampaignAnswer
            text={message.content.campaign.text}
            recommendation={message.content.recommendation}
            sdQueries={message.content.sdQueries}
          />
        )
      case 'GENIES_DATA':
        return <DataAnswer data={message.content.data} />
      case 'GENIES_BRAND':
        return <BrandAnswer text={message.content} />
      case 'AUDIENCE':
        return <AudienceAnswer text={message.content} />
    }
  }

  return <div className={clsx(styles.selectableText, 'cancel-drag')}>{renderMessage()}</div>
}
