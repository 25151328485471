import { WppActionButton, WppIconComment, WppTypography, WppIconClose } from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Rnd } from 'react-rnd'

import { AssistantContent } from 'components/assistant/assistantPopover/assistantContent/AssistantContent'
import styles from 'components/assistant/assistantPopover/AssistantPopover.module.scss'
import { AssistantSettings } from 'components/assistant/assistantPopover/assistantSettings/AssistantSettings'
import { Flex } from 'components/common/flex/Flex'

export const AssistantPopover = () => {
  const { t } = useTranslation()

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isAssistantSettingsVisible, setIsAssistantSettingsVisible] = useState(false)

  const [assistantPosition, setAssistantPosition] = useState({ x: 24, y: 24 })
  const [assistantSize, setAssistantSize] = useState({ width: '460', height: '600' })

  const toggleIsPopoverOpen = (isOpen: boolean) => setIsPopoverOpen(isOpen)
  const toggleIsAssistantSettingsVisible = (isVisible: boolean) => setIsAssistantSettingsVisible(isVisible)

  return (
    <>
      <WppActionButton onClick={() => toggleIsPopoverOpen(true)}>
        <WppIconComment />
      </WppActionButton>
      {isPopoverOpen && (
        <div className={styles.dragSurface}>
          <Rnd
            minWidth={460}
            minHeight={600}
            maxWidth={900}
            bounds="parent"
            position={{ x: assistantPosition.x, y: assistantPosition.y }}
            size={{ width: assistantSize.width, height: assistantSize.height }}
            enableUserSelectHack={false}
            onDragStop={(e, d) => {
              setAssistantPosition({ x: d.x, y: d.y })
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              setAssistantSize({
                width: ref.style.width,
                height: ref.style.height,
              })
              setAssistantPosition({ x: position.x, y: position.y })
            }}
            cancel=".cancel-drag"
            className={styles.assistantRnd}
          >
            <div className={styles.assistant}>
              <Flex className={styles.header} justify="between" align="center" gap={8}>
                <WppTypography type="xl-heading">
                  {isAssistantSettingsVisible ? t('admin.ai_assistant_settings') : t('ai_assistant')}
                </WppTypography>
                <WppActionButton onClick={() => toggleIsPopoverOpen(false)} className="cancel-drag">
                  <WppIconClose color="var(--wpp-grey-color-800)" />
                </WppActionButton>
              </Flex>
              {isAssistantSettingsVisible ? (
                <AssistantSettings onCloseAssistantSettings={() => toggleIsAssistantSettingsVisible(false)} />
              ) : (
                <AssistantContent onOpenAssistantSettings={() => toggleIsAssistantSettingsVisible(true)} />
              )}
            </div>
          </Rnd>
        </div>
      )}
    </>
  )
}
