// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NAUkU{width:100%;margin-bottom:12px;padding:12px 8px;background-color:var(--wpp-grey-color-200);border-radius:var(--wpp-border-radius-m)}.k3T6j{width:100%;white-space:pre-line}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/chatBubble/brandAnswer/BrandAnswer.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,kBAAA,CACA,gBAAA,CACA,0CAAA,CACA,wCAAA,CAGF,OACE,UAAA,CACA,oBAAA","sourcesContent":[".chatBubbleAnswer {\n  width: 100%;\n  margin-bottom: 12px;\n  padding: 12px 8px;\n  background-color: var(--wpp-grey-color-200);\n  border-radius: var(--wpp-border-radius-m);\n}\n\n.text {\n  width: 100%;\n  white-space: pre-line;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatBubbleAnswer": "NAUkU",
	"text": "k3T6j"
};
export default ___CSS_LOADER_EXPORT___;
