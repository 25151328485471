// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QvEiT{margin-bottom:12px;padding:16px;background-color:var(--wpp-grey-color-200);border-radius:var(--wpp-border-radius-m)}.gxQgl{width:100%;max-width:360px;height:100%;margin:0 auto}.fsXA0{white-space:pre-line;text-align:center}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/answerLoadingPlaceholder/AnswerLoadingPlaceholder.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,YAAA,CACA,0CAAA,CACA,wCAAA,CAGF,OACE,UAAA,CACA,eAAA,CACA,WAAA,CACA,aAAA,CAGF,OACE,oBAAA,CACA,iBAAA","sourcesContent":[".card {\n  margin-bottom: 12px;\n  padding: 16px;\n  background-color: var(--wpp-grey-color-200);\n  border-radius: var(--wpp-border-radius-m);\n}\n\n.illustration {\n  width: 100%;\n  max-width: 360px;\n  height: 100%;\n  margin: 0 auto;\n}\n\n.text {\n  white-space: pre-line;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "QvEiT",
	"illustration": "gxQgl",
	"text": "fsXA0"
};
export default ___CSS_LOADER_EXPORT___;
