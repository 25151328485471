// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mhUkA{width:100%;margin-bottom:12px;padding:12px 8px 1px;background-color:var(--wpp-grey-color-200);border-radius:var(--wpp-border-radius-m)}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/chatBubble/dataAnswer/DataAnswer.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,kBAAA,CACA,oBAAA,CACA,0CAAA,CACA,wCAAA","sourcesContent":[".dataCard {\n  width: 100%;\n  margin-bottom: 12px;\n  padding: 12px 8px 1px;\n  background-color: var(--wpp-grey-color-200);\n  border-radius: var(--wpp-border-radius-m);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dataCard": "mhUkA"
};
export default ___CSS_LOADER_EXPORT___;
