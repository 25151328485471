import { WppTypography, WppIconBot } from '@platform-ui-kit/components-library-react'

import styles from 'components/assistant/chat/chatBubble/campaignAnswer/CampaignAnswer.module.scss'
import { MarkdownText } from 'components/assistant/chat/chatBubble/markdownText/MarkdownText'
import { Flex } from 'components/common/flex/Flex'

interface Props {
  text: string
  recommendation: string | null
  sdQueries: string[]
}

export const CampaignAnswer = ({ text, recommendation, sdQueries }: Props) => {
  return (
    <>
      <Flex className={styles.chatBubbleAnswer} direction="row" wrap="nowrap" gap={12}>
        <WppIconBot height={32} width={32} color="var(--wpp-dataviz-color-seq-grey-600)" />
        <WppTypography tag="p" type="s-body" className={styles.text}>
          <MarkdownText text={text} />
        </WppTypography>
      </Flex>
      {recommendation ? (
        <Flex className={styles.chatBubbleAnswer} direction="row" wrap="nowrap" gap={12}>
          <WppIconBot height={32} width={32} color="var(--wpp-dataviz-color-seq-grey-600)" />
          <WppTypography tag="p" type="s-body" className={styles.text}>
            <MarkdownText text={recommendation} />
          </WppTypography>
        </Flex>
      ) : (
        <>
          {sdQueries.map((sdQuery, index) => (
            <Flex className={styles.chatBubbleAnswer} direction="row" wrap="nowrap" gap={12} key={index}>
              <WppIconBot height={32} width={32} color="var(--wpp-dataviz-color-seq-grey-600)" />
              <WppTypography tag="p" type="s-body" className={styles.text}>
                <MarkdownText text={sdQuery} />
              </WppTypography>
            </Flex>
          ))}
        </>
      )}
    </>
  )
}
